import React from 'react'
// import { HashLink } from 'react-router-hash-link'
//icons
// import { FaArrowRight } from 'react-icons/fa'
import { BookOpenIcon, BanknotesIcon, StarIcon, AcademicCapIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { LiaUniversitySolid } from 'react-icons/lia';
import { MdOutlineAssignment } from "react-icons/md";
import { SiSemanticscholar } from "react-icons/si"


const features = [
  {
    name: "Economical Study Options",
    description:
      "Gryphon Overseas provides affordable study options, scholarships, and financial aid to make education accessible. They prioritize budget-friendly solutions for students, enabling them to pursue their dreams without financial barriers.",
    icon: BanknotesIcon,
  },
  {
    name: "Comprehensive Programs and Resources",
    description:
      "Gryphon Overseas empowers students with programs and courses for academic and professional success. They offer resources for test prep, career guidance, and skill development. With tailored support, Gryphon Overseas helps students excel in academia and achieve career goals.",
    icon: BookOpenIcon,
  },
  {
    name: "Scholarship Assistance",
    description:
      "We provide information on available scholarships and assist students in applying.",
    icon: SiSemanticscholar,
  },
  {
    name: "Best University Admissions",
    description:
      "Gryphon Overseas secures top university admissions through personalized guidance. They help with applications, essays, and mock interviews to increase acceptance rates. For medical students, they provide assistance in exam prep and profile building for admission to prestigious medical schools.",
    icon: AcademicCapIcon,
  },
  {
    name: "Guranteed Outcomes",
    description:
      "Gryphon Overseas guarantees successful outcomes. They support students in achieving their goals, including admission to top universities and securing jobs in their desired fields.",
    icon: CheckCircleIcon,
  },
  {
    name: "Maximum Benefits",
    description:
      "Gryphon Overseas prioritizes student success through valuable resources, support, and guidance. They offer budget-friendly programs, exclusive materials, counseling, and networking opportunities for an enhanced educational journey.",
    icon: StarIcon,
  },

  {
    name: "University Selection",
    description:
      "We help students select the right course, college or university,and country based on their academic profile, interests, and budget.",
    icon: LiaUniversitySolid,
  },

  {
    name: "Assistance guidance",
    description:
      "We assist students in the entire application process, including filling out application forms, writing SOPs, essays, and letters of recommendation etc.",
    icon: MdOutlineAssignment,
  },
]

const WhyPrograms = () => {
    return (
        <div className="">
            <div className="flex flex-col items-center">
                <div className="mx-auto max-w-2xl text-center">
                    <p className="mt-2 title">
                        Why Choose Us?
                    </p>
                    <p className="mt-6 subdesc">
                        You can explore the features that we provide with fun and have their own feature.
                    </p>
                </div>
                <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative pl-16">
                                <dt className="font-semibold leading-7 text-gray-800">
                                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-primary shadow-md">
                                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </div>
                                    {feature.name}
                                </dt>
                                <dd className="mt-3 text-sm font-medium lg:text-base leading-5 text-gray-700">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>

    )
}

export default WhyPrograms