/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import axios from 'axios';
import { useState } from "react";
//icons
// import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Switch } from '@headlessui/react'
import { RiInstagramFill, RiFacebookFill, RiWhatsappFill } from 'react-icons/ri';
function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const Connect = () => {
    const [agreed, setAgreed] = useState(false);
    //Getting Details
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    // const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [message, setMessage] = useState('')



    const handleSubmit = async (e) => {
        e.preventDefault(); //prevent the default behaviour (means relooading the browser)

        const config = {
            SecureToken: '2e7ccd16-022b-446b-b30a-36378e2306d2',
            To: ' contact@gryphonoverseas.com',
            From: ' contact@gryphonoverseas.com',
            Subject: "Gryphon Overseas - Contact Us Form",
            Body: "Name: " + firstname + lastname
                // + "<br /> Company: " + company 
                + "<br /> Email: " + email
                + "<br /> Number: " + number
                + "<br /> Message: " + message
        }

        if (window.Email) {
            window.Email.send(config).then(
                message => alert(message)
            );
        }

        try {
            alert("Submitted")
            setFirstname('')
            setLastname('')
            // setCompany('')
            setEmail('')
            setNumber('')
            setMessage('')
            await axios.post("http://localhost:3000/", {
                firstname,
                lastname,
                // company,
                email,
                number,
                message
            }) //send data from frontend to backend
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
    return (
        // <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8"> --original
        <div className="isolate mt-44 mb-36 p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl">
            <div
                className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
                aria-hidden="true"
            >
                <div
                    className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
                    style={{
                        clipPath:
                            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                    }}
                />
            </div>
            <div className="mx-auto text-center">
                <h2 className="title">Contact Us</h2>
                <p className="mt-2 subdesc">
                    We are here to assist you on your study abroad journey.
                </p>
            </div>
            <form action='POST' className="mx-auto mt-16 max-w-xl sm:mt-20">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-600">
                            First name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="first-name"
                                id="first-name"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setFirstname(e.target.value)}
                                value={firstname}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-600">
                            Last name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="last-name"
                                id="last-name"
                                autoComplete="family-name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setLastname(e.target.value)}
                                value={lastname}
                            />
                        </div>
                    </div>
                    {/* <div className='text-red-600'>Error</div> */}
                    {/* <div className="sm:col-span-2">
                        <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-600">
                            Company
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="company"
                                id="company"
                                autoComplete="organization"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setCompany(e.target.value)}
                                value={company}
                            />
                        </div>
                    </div> */}
                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-600">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-600">
                            Phone number
                        </label>
                        <div className="relative mt-2.5">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                                <label htmlFor="country" className="sr-only">
                                    Country
                                </label>
                                <select
                                    id="country"
                                    name="country"
                                    className="h-full rounded-md border-0 bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm"
                                >
                                    <option>IND</option>
                                    <option>Canada</option>
                                    <option>USA</option>
                                    <option>UK</option>
                                </select>
                                {/* <ChevronDownIcon
                                    className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
                                    aria-hidden="true"
                                /> */}
                            </div>
                            <input
                                type="tel"
                                name="phone-number"
                                id="phone-number"
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 px-3.5 py-2 pl-20 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                onChange={(e) => setNumber(e.target.value)}
                                value={number}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-600">
                            Message
                        </label>
                        <div className="mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-800 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                                onChange={(e) => setMessage(e.target.value)}
                                value={message}
                            />
                        </div>
                    </div>
                    <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2 justify-center">
                        <div className="flex h-6 items-center">
                            <Switch
                                checked={agreed}
                                onChange={setAgreed}
                                className={classNames(
                                    agreed ? 'bg-primary' : 'bg-gray-200',
                                    'flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600'
                                )}
                            >
                                <span className="sr-only">Agree to policies</span>
                                <span
                                    aria-hidden="true"
                                    className={classNames(
                                        agreed ? 'translate-x-3.5' : 'translate-x-0',
                                        'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                                    )}
                                />
                            </Switch>
                        </div>
                        <Switch.Label className="text-sm leading-6 text-gray-700">
                            By selecting this, you agree to allow us to contact you via phone
                        </Switch.Label>
                    </Switch.Group>
                </div>
                <div className="mt-10">
                    <button
                        type="submit"
                        value="Submit"
                        onClick={handleSubmit}
                        className="block w-full rounded-xl bg-primary px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-xl hover:bg-primary-hover focus:bg-primary-hover duration-300 transition-colors"
                    >
                        Let's talk
                    </button>
                </div>
            </form>
            <div className="p-2 w-full pt-8 mt-8 border-t border-gray-200 text-center">
                <a href="mailto: contact@gryphonoverseas.com"
                    className="text-primary font-semibold hover:text-gray-500 duration-300 transition-colors">contact@gryphonoverseas.com</a>

                <p className="leading-normal my-5 text-primary border-gray-200 font-semibold">
                    <a
                    // className='hover:text-gray-500 duration-300 transition-colors'
                    >
                        <p>+91 8799607877</p>
                    </a>
                </p>
                <p className="leading-normal my-5 text-gray-700 ">
                    <a
                        className='hover:text-gray-500 duration-300 transition-colors'>
                        214 Trivia Complex, Nr Natubhai Circle
                        <br />Race Course, Vadodara, 390007
                    </a>
                </p>
                <span className="inline-flex">
                    <a
                        href="https://api.whatsapp.com/send?phone=+918799607877&text=Hello, Welcome to our Gryphon Overseas!!"
                        className="ml-4 text-gray-500 hover:text-[green] cursor-pointer text-xl duration-300 transition-colors"
                        target="blank"
                    >
                        <RiWhatsappFill />
                    </a>

                    <a
                        href="https://www.facebook.com/profile.php?id=6155378160201&mibextid=zbWKwL"
                        className="ml-4 text-gray-500 hover:text-[#3b5998] cursor-pointer text-xl duration-300 transition-colors"
                        target="blank"
                    >
                        <RiFacebookFill />
                    </a>
                    <a
                        href="https://www.instgram.com/gryphon_overseas?igsh=cjJwamNdHdoNnZs"
                        className="ml-4 text-gray-500 hover:text-[#e2457a] cursor-pointer text-xl duration-300 transition-colors"
                        target="blank"
                    >
                        <RiInstagramFill />
                    </a>


                </span>
            </div>
        </div>
    )
}
export default Connect
