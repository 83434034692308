import React from "react"

import VisitHero from "../components/VisitHero"
import Visitor from "../components/Visitor"
// import Testimonial from "../components/Testimonial"
// import US from '../components/US';

const Visit = () => {
  return (
    <div className="mb-36 space-y-40">
      <VisitHero />
      <div className="p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl space-y-40">
        <Visitor />
        {/* <US /> */}
        {/* <Benefits /> */}
      </div>
      {/* <Testimonial /> */}
    </div>
  )
}

export default Visit
