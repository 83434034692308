import React from "react"
import { useTypewriter, Cursor } from "react-simple-typewriter"

//images

import immigrate from "../../src/assets/programs/immigrate.jpg"

const ImmigrateHero = () => {
  const [text] = useTypewriter({
    words: ["Our entire team works enthusiastically to help you navigate the path which will take you to your desired immigration goals.","Immigration is a good thing. We should make that as easy as possible."],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  })

  return (
    <section className="overflow-hidden">
      <div className="bg-purple-50 overflow-hidden">
        {/* <div className="pt-20 pb-14 bg-purple-50 overflow-hidden"> */}
        <div className="px-10 mx-auto py-44 sm:px-20">
          {/* <div className="px-10 mx-auto py-36 sm:px-20 sm:py-16 lg:py-28"> */}
          <div className="flex flex-wrap -m-8">
            <div className="w-full md:w-1/2 p-8">
              <div className="md:max-w-lg">
                <h1 className="text-4xl text-center font-semibold tracking-tight text-gray-800 sm:text-5xl sm:text-left">
                  {/* At Gryphon Overseas */}
                  {" "}
                  <span className="text-primary shadow-md">{text}</span>
                  <span className="text-gray-800">
                    <Cursor cursorStyle="|" />
                  </span>
                </h1>

                <p className="mt-6 text-sm text-gray-700 font-medium lg:text-base mb-24 text-center sm:text-left">
                Immigration has become a dream for everyone in recent times for people willing to settle abroad. People are willing to move abroad for study, work, or for better quality of life.
                </p>
                <div className="max-w-lg">
                  <div className="flex flex-wrap m-1.5 items-center">
                    <div className="w-auto p-1.5">
                      <div className="flex flex-wrap">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 p-8">
              {/* <img className="transform hover:-translate-y-2 transition duration-500" src="basko-assets/images/headers/dashboard.png" alt="" /> */}
              <img
                class="object-cover object-center mx-auto shadow-2xl rounded-2xl sm:rounded-3xl"
                alt="immigrate"
                src={immigrate}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImmigrateHero
