import React from "react"

const ImmigrateBenefits = () => {
  return (
    <div>
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">Everyone</span>
          </span>{" "}
          Start your process to immigrate abroad Today
        </h2>
      </div>
      <div className="mt-16 text space-y-8">
        <p>
          {/* At{" "} */}
          <span className="text-primary font-semibold hover:text-gray-700 cursor-pointer duration-300 transition-colors">
            {" "}
            www.gryphonoverseas.com
          </span>
          , Immigrating abroad in one of the world’s leading countries is one of
          the most life-changing things you can do. Few reasons people migrate
          abroad are:
        </p>
        <div className="mt-16 text space-y-1">
          <p>Better financial prospects.</p>
          <p>Quality and Standard of living.</p>
          <p>Better healthcare and education.</p>
          <p>Bring your near and dear ones abroad.</p>
        </div>
      </div>

{/* USA */}
<div className="mt-16 text space-y-8">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative"></span>
            </span>{" "}
            Migrate to USA
          </h2>
        </div>
        <div className="mt-16 text space-y-8">
        <p>
          Live your American Dream!! Who would not be mesmerized by the glitz and glam of Hollywood, or the hustle-bustle of midtown Manhattan? From sports to TV and everything in between, America’s influence extends everywhere, providing an enticing image for those interested in emigrating.
       </p>
       </div>
      </div>

      {/* Australia */}
      <div className="mt-16 text space-y-8">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative"></span>
            </span>{" "}
            Migrate to Australia
          </h2>
        </div>
        <div className="mt-16 text space-y-8">
          <p>
            A thriving economy, Australia has many opportunities for a newcomer.
            Enjoy the weather, quality of life and increased earning potential.
            There are many pathways to move to Australia - Student, Investor, PR
            for skilled professionals.
          </p>
        </div>
      </div>


      {/* Canada */}
      <div className="mt-16 text space-y-8">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative"></span>
            </span>{" "}
            Migrate to Canada
          </h2>
        </div>
        <div className="mt-16 text space-y-8">
          <p>
            One of the top 5 countries migrants choose to immigrate, home away
            from home with lots of Indians already habitating and settle in
            Canada.
          </p>
        </div>
      </div>

      {/* Germany */}
      <div className="mt-16 text space-y-8">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative"></span>
            </span>{" "}
            Migrate to Germany
          </h2>
        </div>
        <div className="mt-16 text space-y-8">
          <p>
            The high demand for skilled workers in Germany generates many
            opportunities for immigrants in diverse industries and sectors.
            Germany is said to offer among the quickest visa decisions.
            Moreover, you can access the entire European Union (EU) when you
            migrate to Germany.
          </p>
        </div>
      </div>

      {/* UK */}
      <div className="mt-16 text space-y-8">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <h2 className="max-w-lg mb-6 title sm:mx-auto text-center">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="9a29985a-fc16-419b-ae53-1670f5ca4491"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#9a29985a-fc16-419b-ae53-1670f5ca4491)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative"></span>
            </span>{" "}
            Migrate to the UK for unparalleled career growth
          </h2>
        </div>
        <div className="mt-16 text space-y-7">
          <p>
            Take advantage of the Tier 2 visa program for skilled professionals
            in healthcare, construction, IT and more.
          </p>
          <p>
            Besides Tier 2, you can choose to migrate through Student, Family,
            Business or Investor visa.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ImmigrateBenefits
