import React from "react"

import ImmigrateHero from "../components/ImmigrateHero"
import ImmigrateBenefits from "../components/ImmigrateBenefits"

const Immigrate = () => {
  return (
    <div className="mb-36 space-y-40">
      <ImmigrateHero />
      <div className="p-6 mx-auto max-w-sm sm:max-w-xl md:max-w-full lg:max-w-screen-xl space-y-40">
        <ImmigrateBenefits />
        {/* <Benefits /> */}
      </div>
      {/* <Testimonial /> */}
    </div>
  )
}

export default Immigrate
